import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => (
  <nav className="fixed top-0 left-0 w-full z-50 bg-black bg-opacity-50 font-normal">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between h-20 lg:h-28"> {/* Further increased height for large screens */}
        <div className="flex items-center">
          <span className="text-white text-xl md:text-2xl lg:text-3xl">Standard Data</span> {/* Increased font size on lg screens */}
        </div>
        <div className="flex">
          <Link to="/" className="text-gray-300 hover:text-white px-3 py-2 md:px-4 md:py-3 lg:px-6 lg:py-4 rounded-md text-sm md:text-base lg:text-lg">Home</Link>
          <Link to="/product" className="text-gray-300 hover:text-white px-3 py-2 md:px-4 md:py-3 lg:px-6 lg:py-4 rounded-md text-sm md:text-base lg:text-lg">Product</Link>
        </div>
      </div>
    </div>
  </nav>
);

export default Navbar;
