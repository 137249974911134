import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { image1, image2 } from './images/images';
import { useRef, useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import React from 'react';

// Remove this import if it exists
// import Navbar from './Navbar';

function Test2() {
    const parallaxRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const [scale, setScale] = useState(1);
    const [top, setTop] = useState(-300);
    const [Mobiletop, setMobileTop] = useState(-100);
    const [scroll, setScroll] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        reason: '',
        message: ''
    });
    const [formSubmitted, setFormSubmitted] = useState(false);

    // Spring animations
    const [textSpring, setTextSpring] = useSpring(() => ({
        opacity: 0,
        transform: 'translateY(50%)',
        config: { duration: 500, tension: 170, friction: 26 }
    }));

    const [image1Spring, setImage1Spring] = useSpring(() => ({
        top: '0%',
        transform: 'scale(1)',
        left: 0,
        config: { duration: 800, tension: 10, friction: 17 }
    }));

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSeF9G1MUTv6AZE2CsNnf1pqEuqqwZzBZZTSvp8Qd2o0yO9wnA/formResponse';
        
        // Create a FormData object
        const googleFormData = new FormData();
        googleFormData.append('entry.1449978194', formData.name);
        googleFormData.append('entry.2052991969', formData.email);
        googleFormData.append('entry.1250740815', formData.reason);
        googleFormData.append('entry.1749420590', formData.message);

        try {
            // Submit the form
            await fetch(formUrl, {
                method: 'POST',
                mode: 'no-cors', // This is important to prevent CORS issues
                body: googleFormData
            });

            console.log('Form submitted successfully');
            setFormData({ name: '', email: '', reason: '', message: '' });
            setFormSubmitted(true); // Set form as submitted
        } catch (error) {
            console.error('Error submitting form:', error);
            // Optionally, show an error message to the user
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Update isMobile based on screen width
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        const handleScroll = () => {
            if (parallaxRef.current) {
                const currentScroll = parallaxRef.current.current;
                setScroll(currentScroll);
                const newScale = Math.min(1 + currentScroll / 15000, 2);
                setScale(newScale);
                
                // Adjust these values for better mobile performance
                const newTop = Math.max(-300 + currentScroll / 2, -300);
                const newMobMar = Math.max(-110 + currentScroll / 2, -110);
                
                setTop(newTop <= 0 ? newTop : 0);
                setMobileTop(newMobMar <= 0 ? newMobMar : 0);

                if (currentScroll > 500) {
                    setImage1Spring({
                        top: isMobile ? '5%' : '30%', // Adjusted for mobile
                        marginLeft: isMobile ? '0%' : '10%',
                        transform: isMobile ? 'scale(0.6)' : 'scale(0.4)', // Reduced scale for mobile
                        left: isMobile ? 0 : -250,
                    });
                } else {
                    setImage1Spring({
                        top: '0%',
                        transform: 'scale(1)',
                        left: 0,
                    });
                }

                if (currentScroll > 750) {
                    setTextSpring({
                        opacity: 1,
                        transform: 'translateY(0%)',
                    });
                } else {
                    setTextSpring({
                        opacity: 0,
                        transform: 'translateY(-50%)',
                    });
                }
            }
        };

        const parallaxContainer = parallaxRef.current.container.current;
        parallaxContainer.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            parallaxContainer.removeEventListener('scroll', handleScroll);
        };
    }, [isMobile, setImage1Spring, setTextSpring, Mobiletop]);

  return (
    <div style={{ width: '100%', height: '100%', background: '#253237', overflow: 'hidden' }}>
      {/* Remove the Navbar component from here */}
      <Parallax ref={parallaxRef} pages={3} style={{ background: 'black' }}>
        <ParallaxLayer offset={0} speed={1} style={{ backgroundColor: 'black' }} />

        <ParallaxLayer offset={0} speed={2} style={{ pointerEvents: 'none', marginLeft: 2, background: 'transparent' }} sticky={{start:0,end:3}}>
          <img src={`${image2}`} style={{ width: '100%', position:'absolute', bottom:0, scale:scale, background: 'transparent' }} alt='image-of-factory'/>
        </ParallaxLayer>


    {/* Content layer that should move up when scrolling */}
    <ParallaxLayer 
      offset={0} 
      speed={0} 
      style={{ 
        backgroundColor: 'black', 
        overflow: 'hidden', 
        width: '100%', 
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        zIndex: 1 // Ensure this layer is above others if needed
      }} 
      sticky={{ start: 1, end: 3 }} // Adjusted start value
    >
      <animated.img 
        src={`${image1}`} 
        style={{ 
          width: '100%', 
          position: 'absolute', 
          top: isMobile ? (scroll < 200 ? Mobiletop : '5%') : (scroll < 500 ? top : image1Spring.top),
          transform: image1Spring.transform, 
          left: image1Spring.left,
          pointerEvents: 'none'
        }} 
      />
      
      <animated.div 
        style={{ 
          opacity: textSpring.opacity, 
          transform: textSpring.transform,
          width: isMobile ? '90%' : '30%', // Increase width for mobile
          maxHeight: '90vh',
          overflowY: 'auto',
          pointerEvents: 'auto',
          padding: '20px',
          marginRight: isMobile ? 'auto' : '10%', // Center on mobile
          marginLeft: isMobile ? 'auto' : 'initial', // Center on mobile
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: isMobile ? '30%' : '0' // Add top margin on mobile
        }}
      >
        <h2 style={{ fontSize: isMobile ? 20 : 32, color: 'white' }} className="font-normal">
          Attain, Train, and Retain High Quality Manufacturing Talent.
        </h2>
        <br />
        <h2 style={{ fontSize: isMobile ? 15 : 20, color: 'grey' }} className="font-normal">
          The advanced skills gap is the greatest risk to our nation — but it doesn't have to be. Together, we can rebuild the American manufacturing workforce.
        </h2>
        <br />
        <h2 style={{ fontSize: isMobile ? 18 : 24, color: 'white' }} className="font-normal">
          Interested in learning more?
        </h2>
        {formSubmitted ? (
            <p style={{ fontSize: isMobile ? 16 : 20, color: 'white', marginTop: '20px' }} className="font-normal">
                Thank you for your interest. We will be in touch with you soon!
            </p>
        ) : (
            <form onSubmit={handleSubmit} style={{ marginTop: '20px', pointerEvents: 'auto' }}>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Name"
                    style={inputStyle}
                    required
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    style={inputStyle}
                    required
                />
                <select
                    name="reason"
                    value={formData.reason}
                    onChange={handleInputChange}
                    style={inputStyle}
                    required
                >
                    <option value="">Select your role</option>
                    <option value="job seeker">Job Seeker</option>
                    <option value="employer">Employer Hiring Talent</option>
                    <option value="other">Other</option>
                </select>
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="Message (optional)"
                    style={{...inputStyle, height: '100px', resize: 'vertical'}}
                />
                <button 
                    type="submit"
                    style={{
                        padding: isMobile ? '8px 15px' : '10px 20px',
                        fontSize: isMobile ? '14px' : '18px',
                        color: 'black',
                        backgroundColor: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginTop: '10px',
                        width: '100%'
                    }}
                >
                    Submit
                </button>
            </form>
        )}
      </animated.div>
    </ParallaxLayer>




<ParallaxLayer 
  offset={0} 
  speed={0} 
  className='w-full items-center'
  style={{ marginTop: '64px' }} // Add this line
>
  <h2 
    style={{ 
      fontSize: isMobile ? '50px' : '100px',
      color: 'white', 
      lineHeight: isMobile ? '1.1' : '1.2',
      textAlign: 'center', 
      marginTop: isMobile ? 'calc(45% + 64px)' : 'calc(10% + 64px)', // Adjust this line
      marginBottom: isMobile ? '10px' : '20px',
    }} 
    className='font-normal'
  >
    Standard Data
  </h2>
  <h3 
    style={{ 
      fontSize: isMobile ? '20px' : '30px',
      color: 'grey', 
      textAlign: 'center', 
      marginTop: isMobile ? '10px' : '-1.5%',
    }}
    className="font-normal"
  >
    Increasing US Manufacturing Workforce Capacity
  </h3>
</ParallaxLayer>

     
      </Parallax>
    </div>
    
  );
}

const inputStyle = {
  width: '100%',
  padding: '8px',
  marginBottom: '10px',
  borderRadius: '5px',
  border: 'none',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
};

export default Test2;
