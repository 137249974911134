import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Test2 from './test2';
import Product from './product';
import Navbar from './navbar'; // We'll create this in the next step

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Test2 />} />
        <Route path="/product" element={<Product />} />
      </Routes>
    </Router>
  );
}

export default App;
