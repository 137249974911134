import React, { useState, useEffect, useCallback } from 'react';
import { useTransition, animated } from '@react-spring/web';
import { useSwipeable } from 'react-swipeable';
import MapScreenshot from './images/MapScreenshot.png';
import RecommendedCandidateScreenshot from './images/RecommendedCandidateScreenshot.png';
import FullScreenImage from './components/FullScreenImage';

function Product() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);

  const features = [
    {
      image: MapScreenshot,
      title: "Global Talent Command Center",
      description: "Match Qualified Candidates to Roles"
    },
    {
      image: RecommendedCandidateScreenshot,
      title: "Candidate Recommendations",
      description: "Get intelligent candidate recommendations based on role requirements and candidate profiles."
    }
  ];

  const transitions = useTransition(currentIndex, {
    from: { opacity: 0, transform: direction > 0 ? 'translate3d(100%,0,0)' : 'translate3d(-100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: direction > 0 ? 'translate3d(-50%,0,0)' : 'translate3d(50%,0,0)' },
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const changeFeature = useCallback((newDirection) => {
    setDirection(newDirection);
    setCurrentIndex((prevIndex) => {
      if (newDirection > 0) {
        return (prevIndex + 1) % features.length;
      } else {
        return (prevIndex - 1 + features.length) % features.length;
      }
    });
  }, [features.length]);

  const nextFeature = () => changeFeature(1);
  const prevFeature = () => changeFeature(-1);

  const handlers = useSwipeable({
    onSwipedLeft: nextFeature,
    onSwipedRight: prevFeature,
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  });

  const handleImageClick = (e) => {
    if (isMobile) {
      e.stopPropagation();
      setShowFullScreen(true);
    }
  };

  const closeFullScreen = () => {
    setShowFullScreen(false);
  };

  return (
    <div style={{ 
      width: '100%', 
      height: '100vh', 
      background: 'black', 
      color: 'white', 
      overflow: 'hidden',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div style={{
        paddingTop: isMobile ? '120px' : '100px', // Significantly increased padding-top
        marginBottom: isMobile ? '10px' : '20px'
      }}>
        <h1 style={{ 
          fontSize: isMobile ? '36px' : '72px', 
          textAlign: 'center', 
          padding: isMobile ? '10px 0' : '20px 0',
          margin: 0
        }} className="font-normal">
          Product Highlights
        </h1>
      </div>
      
      <div 
        {...(isMobile ? handlers : {})}
        style={{ 
          position: 'relative', 
          width: '100%', 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflow: 'hidden'
        }}
      >
        {transitions((style, index) => (
          <animated.div
            style={{
              ...style,
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 20px'
            }}
          >
            <img 
              src={features[index].image} 
              alt={features[index].title} 
              style={{ 
                maxWidth: isMobile ? '90%' : '60%', 
                maxHeight: isMobile ? '60%' : '80%', 
                objectFit: 'contain', 
                marginBottom: isMobile ? '20px' : '0',
                marginRight: isMobile ? '0' : '40px',
                cursor: isMobile ? 'pointer' : 'default'
              }} 
              onClick={handleImageClick}
            />
            <div style={{
              maxWidth: isMobile ? '100%' : '30%',
              textAlign: isMobile ? 'center' : 'left'
            }}>
              <h2 style={{ fontSize: isMobile ? '24px' : '32px', marginBottom: '10px' }} className="font-normal">
                {features[index].title}
              </h2>
              <p style={{ fontSize: isMobile ? '16px' : '20px', color: 'grey' }} className="font-normal">
                {features[index].description}
              </p>
            </div>
          </animated.div>
        ))}

        <button 
          onClick={prevFeature} 
          style={{
            position: 'absolute',
            left: isMobile ? '5px' : '20px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'rgba(255,255,255,0.1)',
            border: 'none',
            color: 'white',
            fontSize: isMobile ? '24px' : '30px',
            padding: isMobile ? '12px 16px' : '15px 20px',
            cursor: 'pointer'
          }}
        >
          &#8592;
        </button>
        <button 
          onClick={nextFeature}
          style={{
            position: 'absolute',
            right: isMobile ? '5px' : '20px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'rgba(255,255,255,0.1)',
            border: 'none',
            color: 'white',
            fontSize: isMobile ? '24px' : '30px',
            padding: isMobile ? '12px 16px' : '15px 20px',
            cursor: 'pointer'
          }}
        >
          &#8594;
        </button>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
        {features.map((_, index) => (
          <div
            key={index}
            style={{
              width: isMobile ? '8px' : '10px',
              height: isMobile ? '8px' : '10px',
              borderRadius: '50%',
              background: index === currentIndex ? 'white' : 'grey',
              margin: '0 5px',
              cursor: 'pointer'
            }}
            onClick={() => {
              setDirection(index > currentIndex ? 1 : -1);
              setCurrentIndex(index);
            }}
          />
        ))}
      </div>

      {showFullScreen && (
        <FullScreenImage 
          image={features[currentIndex].image} 
          onClose={closeFullScreen} 
        />
      )}
    </div>
  );
}

export default Product;
